import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import usePriceSwitch from 'src/hooks/usePriceSwitch';
import useCurrency from 'src/hooks/useCurrency';
import * as S from './styles';

const clientPageReload = (router) => {
  const { asPath, pathname } = router;
  router.replace(pathname, asPath);
};

const mapToCurrencyOptions = (availCurriencies) =>
  availCurriencies.map((cur) => ({
    label: cur.name,
    value: cur,
  }));

const mapToPriceTypeOptions = (priceTypesAvail) =>
  priceTypesAvail.map((priceType) => ({
    label: priceType.name,
    value: priceType,
  }));

const defaultProps = {
  afterChange: () => null,
  selectedPriceTypeApiId: null,
  invertSelects: false,
};

export const PriceCurrencySelects = ({
  selectedPriceTypeApiId,
  afterChange,
  invertSelects,
  ...restProps
} = defaultProps) => {
  const router = useRouter();
  const [priceTypesAvailable, setPriceType] = usePriceSwitch(afterChange);
  const [currencyInfo, setCurrency] = useCurrency({
    onCompletedCallback: afterChange,
  });
  const { availableCurrencies, currentCurrency } = currencyInfo;
  const selectedCurrencyIdx = availableCurrencies.findIndex(({ id }) => id === currentCurrency.id);

  let selectedPriceTypeIdx = null;
  if (!selectedPriceTypeApiId) {
    selectedPriceTypeIdx = priceTypesAvailable.findIndex(({ selected }) => !!selected);
  } else {
    selectedPriceTypeIdx = priceTypesAvailable.findIndex(({ type }) => type === selectedPriceTypeApiId);
  }

  const handleChangeSelectPriceType = useCallback(
    async ({ selectedItem }) => {
      await setPriceType(selectedItem.value.type);
      clientPageReload(router);
    },
    [router, setPriceType],
  );

  const handleChangeSelectCurrencies = useCallback(
    async ({ selectedItem }) => {
      await setCurrency(selectedItem.value.id);
      clientPageReload(router);
    },
    [router, setCurrency],
  );

  const priceTypeOptions = useMemo(() => mapToPriceTypeOptions(priceTypesAvailable), [priceTypesAvailable]);
  const availableCurrenciesItems = useMemo(() => mapToCurrencyOptions(availableCurrencies), [availableCurrencies]);

  return (
    <S.PriceCurrencySelect invertSelects={invertSelects} {...restProps}>
      <S.Select
        id="price-selection"
        selectedItemId={selectedPriceTypeIdx}
        items={priceTypeOptions}
        handleSelectedItemChange={handleChangeSelectPriceType}
      />{' '}
      <S.Select
        id="currency-selection"
        selectedItemId={selectedCurrencyIdx}
        items={availableCurrenciesItems}
        handleSelectedItemChange={handleChangeSelectCurrencies}
      />
    </S.PriceCurrencySelect>
  );
};

PriceCurrencySelects.defaultProps = defaultProps;
