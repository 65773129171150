export const cuttingsKeys = {
  TO_CUT: 'to_cut',
  AS_A_WHOLE: 'as_a_whole',
  ROLL: 'roll',
};

export const cuttingsMap = new Map([
  [0, cuttingsKeys.TO_CUT],
  [1, cuttingsKeys.AS_A_WHOLE],
  [2, cuttingsKeys.ROLL],
]);
