import styled, { css } from 'styled-components';
import SimpleBarComponent from 'simplebar-react';
import { em, rem } from 'polished';
import { only, up } from 'styled-breakpoints';
import ButtonComponent from '../Button';
import HeadingComponent from '../Heading';
import CheckboxComponent from '../Checkbox';
import _Text from 'src/components/Text';
import { breakpointsKeys } from 'src/enums/breakpoints';
import _AddToBasketInput from 'src/components/AddToBasketInput';

import { priceColorsKeys } from 'src/enums/priceColors';

import ImportedPriceCurrencySelects from '../PriceCurrencySelects';

import ImportedLastPriceInfo from 'src/components/LastPriceInfo';
import ImportedBadge from '../Badge';
import ImportedIcon from '../Icon';

const createStylesForProductListPrice = () => css`
  display: grid;
  grid-template-areas: ${({ labelAndAmountHidden }) => (!labelAndAmountHidden ? '"amount price"' : '"price price"')};

  grid-template-columns: ${em(35)} auto;
  grid-column-gap: ${em(10)};

  ${up(breakpointsKeys.TABLET)} {
    grid-template-areas: 'amount price';
    ${({ addBottomMargin }) => addBottomMargin && `margin-bottom: unset`};
    ${({ addMarginBetweenPrices }) => addMarginBetweenPrices && `margin-bottom: ${em(10)}`};
    gap: ${rem(10)};
  }
`;

export const PriceCurrencySelects = styled(ImportedPriceCurrencySelects)`
  display: flex;
  justify-content: flex-end;
  gap: 0.5em;
  margin-bottom: 0.5em;

  button {
    font-size: ${rem(14)};
    padding: 0.5em;

    &:not(:disabled):hover,
    &:not(:disabled):focus {
      background: inherit;
    }
  }

  ${up(breakpointsKeys.TABLET)} {
    button {
      font-size: ${rem(14)};
    }
  }
`;

export const ProductPricesList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${rem(5)};
  justify-content: flex-start;
  ${up(breakpointsKeys.TABLET)} {
    margin-top: 0;
    margin-left: ${rem(20)};
  }
`;

const ItemValue = styled.span`
  color: ${(p) => p.theme.productPricesList.color.value};

  margin-top: ${rem(2)};
`;

export const Amount = styled(ItemValue)`
  margin-top: 0;
  ${(p) => p.theme.helpers.fontFamilyWithFallback(p.theme.productPricesList[p.variant].fontFamily)};
  justify-self: left;
  align-self: ${({ withBadges, shouldAlignToTop }) => (withBadges || shouldAlignToTop ? 'flex-start' : 'flex-end')};
  display: ${({ labelAndAmountHidden }) => (labelAndAmountHidden ? 'none' : 'block')};

  ${({ shouldAlignToTop }) => shouldAlignToTop && `margin-top: ${em(3)}`}
  line-height: normal;

  ${({ theme, isFirstPriceOnTheList }) =>
    isFirstPriceOnTheList
      ? `font-family: ${theme.global.fontFamily.tertiary}`
      : `font-family: ${theme.global.fontFamily.primary}`};
  ${({ isFirstPriceOnTheList, theme }) =>
    isFirstPriceOnTheList
      ? `font-size: ${theme.typography.fontSize.s3}`
      : `font-size: ${theme.typography.fontSize.s2}`};

  &::after {
    content: '+';
  }

  ${up(breakpointsKeys.TABLET)} {
    align-self: ${({ shouldAlignToTop }) => (shouldAlignToTop ? 'flex-start' : 'flex-end')};
    opacity: ${({ labelAndAmountHidden }) => (labelAndAmountHidden ? 0 : 1)};
    display: block;
    ${({ theme, isFirstPriceOnTheList }) =>
      isFirstPriceOnTheList && `font-family: ${theme.global.fontFamily.secondary}`};
    ${({ isFirstPriceOnTheList, theme }) =>
      isFirstPriceOnTheList
        ? `font-size: ${theme.typography.fontSize.s3}`
        : `font-size: ${theme.typography.fontSize.s1}`};
    ${({ isFirstPriceOnTheList, theme }) => !isFirstPriceOnTheList && `color: ${theme.defaultPalette.monochrome[3]}`};
    display: flex;
  }
`;

export const Discount = styled(ItemValue)`
  justify-self: right;
  margin-left: 5px;

  line-height: ${rem(26.5)};
  align-self: ${({ withBadges }) => (withBadges ? 'flex-start' : 'flex-end')};

  ${(p) =>
    p.hasDiscount &&
    css`
      &::after {
        content: '%';
      }
    `}
`;

export const Price = styled.div`
  ${({ theme }) => theme.helpers.fontFamilyWithFallback(theme.typography.type.primary)};
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.productPricesList.afterPricePiece)};
  color: ${(p) => p.theme.productPricesList.colors[p.priceVariant]};
  position: relative;
  text-align: left;
  ${({ showPriceSwitcher }) => !showPriceSwitcher && 'grid-area: price'};
  display: flex;
  flex-direction: column;
  align-self: ${({ withLastPrice }) => (withLastPrice ? 'flex-start' : 'flex-end')};
  line-height: ${({ showPriceSwitcher }) => (!showPriceSwitcher ? 'auto' : rem(26.5))};
  ${up(breakpointsKeys.TABLET)} {
    line-height: ${({ showPriceSwitcher }) => (!showPriceSwitcher ? rem(24) : rem(26.5))};
  }
`;

export const SelectedIcon = styled(ImportedIcon)`
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.productPricesList.fontSize.icon)}
  color: ${(p) => p.theme.productPricesList.color.selectionIcon};
  position: absolute;
  right: 100%;
  top: 50%;
  margin-right: 0.5em;
  transform: translateY(-50%);
`;

export const CrossedPrice = styled.div`
  text-decoration: line-through;
  color: ${(p) => p.theme.price.color.normal};
  white-space: nowrap;
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.productPricesList.fontSize.crossedPrice)};
  line-height: ${em(16)};
`;

export const PriceUnit = styled.span`
  &::before {
    content: ' / ';
  }
`;

export const PriceValue = styled.span`
  ${(p) => p.theme.helpers.fontFamilyWithFallback(p.theme.productPricesList[p.variant].fontFamily)};
  ${({ priceVariant }) =>
    priceVariant === priceColorsKeys.EXTRA_DISCOUNT && `font-family: "Skatta Sans Medium", sans-serif`};
  ${({ theme, isFirstPriceOnTheList }) =>
    isFirstPriceOnTheList
      ? `font-family: ${theme.global.fontFamily.tertiary}`
      : `font-family: ${theme.global.fontFamily.primary}`};
  line-height: normal;
  ${({ isFirstPriceOnTheList, theme }) =>
    isFirstPriceOnTheList
      ? `font-size: ${theme.typography.fontSize.s3}`
      : `font-size: ${theme.typography.fontSize.s2}`};

  ${up(breakpointsKeys.TABLET)} {
    white-space: nowrap;
    ${({ theme, isFirstPriceOnTheList }) =>
      isFirstPriceOnTheList && `font-family: ${theme.global.fontFamily.secondary}`};
    ${({ isFirstPriceOnTheList, theme }) =>
      isFirstPriceOnTheList
        ? `font-size: ${theme.typography.fontSize.s3}`
        : `font-size: ${theme.typography.fontSize.s1}`};
    ${({ isFirstPriceOnTheList, theme }) => !isFirstPriceOnTheList && `color: ${theme.defaultPalette.monochrome[3]}`};
  }
`;

export const Badge = styled(ImportedBadge)`
  align-self: ${({ theme }) => (theme.showPriceSwitcher ? 'flex-start' : 'flex-end')};
  margin-left: 0;
  display: unset;
  padding: ${rem(2)} ${rem(6)};
`;

export const BadgeContainer = styled.div`
  align-self: flex-start;
  ${up(breakpointsKeys.TABLET)} {
    margin-bottom: ${em(7)};
  }
`;

export const PriceContainer = styled.div`
  ${createStylesForProductListPrice()};
`;

export const BadgesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${em(3)};
  ${up(breakpointsKeys.TABLET)} {
    gap: ${em(1)};
  }
`;

export const LastPricesInfoWrapper = styled.div`
  text-align: center;
  color: ${(p) => p.theme.productCardSummary.color.lastPricesInfo};
  font-size: ${(p) => p.theme.productCardSummary.fontSize.lastPricesInfo};

  ${up(breakpointsKeys.TABLET)} {
    margin-top: ${em(4)};
  }
`;

export const ProductBadgesWrapper = styled.div`
  margin-bottom: ${rem(4)};
  ${up(breakpointsKeys.TABLET)} {
    display: flex;

    margin-left: ${rem(40)};
    grid-area: bad;
  }
`;

export const LastPriceInfo = styled(ImportedLastPriceInfo)`
  ${({ separateLines }) => separateLines && 'text-align: left'};
  ${only(breakpointsKeys.TABLET)} {
    white-space: break-spaces;
    text-align: left;
  }
`;

export const BrandAndSeriesFilter = styled.div`
  cursor: pointer;
  display: inline;
  margin-right: ${rem(8)};
  color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  ${up(breakpointsKeys.TABLET)} {
    font-size: ${({ theme }) => theme.typography.fontSize.s2};
    color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  }
  ${up(breakpointsKeys.DESKTOP)} {
    margin-right: 0;
    &:hover {
      color: ${(p) => p.theme.defaultPalette.blazeOrange[0]};
    }
  }
`;

export const NameAndSeriesFilterWrapper = styled.div`
  display: flex;
  gap: ${rem(20)};
`;

export const IndexText = styled(_Text)`
  color: ${({ theme }) => theme.defaultPalette.monochrome[3]};
  ${up(breakpointsKeys.TABLET)} {
    color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
    font-size: ${({ theme }) => theme.typography.fontSize.s2};
  }
`;

export const HeaderAndImgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BasketPartsSelection = styled.div`
  max-width: ${(p) => p.limitWidth && em(p.theme.form.maxWidth.small)};
  width: ${(p) => p.limitWidth && '85vw'};
  background: none;
  padding: 1em 0;

  ${up('tablet')} {
    max-width: unset;
    width: ${(p) => p.limitWidth && '85vw'};
    padding: 0;
  }
  ${up(breakpointsKeys.DESKTOP)} {
    max-width: ${rem(800)};
    width: ${(p) => p.limitWidth && '65vw'};
  }
`;

export const SimpleBar = styled(SimpleBarComponent)`
  max-height: 75vmin;
  margin-top: 1em;

  & * {
    scroll-behavior: smooth;
  }

  ${up('tablet')} {
    max-height: 50vmin;
  }
`;

export const CenteredTextSection = styled.div`
  text-align: center;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: ${rem(12)} 0 ${rem(22)};
  ${up(breakpointsKeys.TABLET)} {
    flex-direction: row;
  }
`;

export const ProductImg = styled.img`
  width: ${rem(80)};
  object-fit: contain;
  max-height: ${rem(80)};

  margin-right: ${rem(10)};
  ${up(breakpointsKeys.TABLET)} {
    max-height: ${rem(80)};
    width: auto;
    margin-right: ${rem(15)};
  }
`;

export const Heading = styled(HeadingComponent)`
  margin-bottom: 0;
  font-family: ${({ theme }) => theme.global.fontFamily.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
`;
export const ProductName = styled(HeadingComponent)`
  font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
  font-size: ${({ theme }) => theme.typography.fontSize.s3};
  width: auto;
  ${up(breakpointsKeys.TABLET)} {
    margin-bottom: 0;
  }
`;

export const Totals = styled.div`
  padding-bottom: 1em;

  ${up('tablet')} {
    font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
    padding-bottom: 0;
    white-space: nowrap;
    display: flex;
    gap: ${rem(3)};
    align-items: center;
    justify-content: center;
    min-width: ${rem(200)};

    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Summary = styled.footer`
  padding-top: ${rem(10)};

  ${up('tablet')} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Buttons = styled.div`
  margin-right: ${rem(10)};
  & > * + * {
    margin-left: 1em;
  }
`;

export const Note = styled.div`
  margin-bottom: ${rem(15)};
  ${up(breakpointsKeys.TABLET)} {
    margin-bottom: 0;
  }
  ${(p) => p.theme.helpers.dynamicFontSize(p.theme.typography.sizesSet.r8)};
  ${({ variant, theme }) => variant === 'error' && `background-color: ${theme.defaultPalette.pastelPink[0]}`};
  ${({ variant, theme }) => variant === 'info' && `background-color: ${theme.defaultPalette.sandYellow[0]}`};
  padding: 0.5em 1em;
  text-align: right;
  font-style: italic;
`;

export const ExtendedDeliveryTimeNote = styled(_Text)`
  margin-bottom: 0;

  user-select: none;
`;

export const Button = styled(ButtonComponent)`
  text-transform: uppercase;
  ${up(breakpointsKeys.TABLET)} {
    font-size: ${({ theme }) => theme.typography.fontSize.s1};
  }
`;

export const AddNextButtonWrapper = styled.div`
  text-transform: lowercase;
  margin-top: 0.5em;
  display: ${(p) => (p.mobileVersion ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  ${up('tablet')} {
    display: ${(p) => (p.mobileVersion ? 'none' : 'flex')};
  }
`;

export const ControlsLabel = styled.span`
  font-weight: normal;
  margin-right: 0.5em;
  cursor: pointer;
`;

export const ControlsButton = styled(Button)`
  ${(p) => p.theme.helpers.transition(['background', 'color'])};
  background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
  color: ${({ theme }) => theme.addToBasketInput.controls.color.default};

  padding: ${rem(2)} ${rem(11)};
  .content {
    font-size: ${rem(16)};
  }

  &:not(:disabled):hover {
    background: ${(p) => p.theme.addToBasketInput.controls.bg.active};
    color: ${(p) => p.theme.addToBasketInput.controls.color.active};
  }
`;

export const Checkbox = styled(CheckboxComponent)`
  font-size: 1.2em;
  vertical-align: middle;

  & .labelBox {
    margin-bottom: 0 !important;
  }

  ${up('tablet')} {
    margin-top: ${em(5)};
    margin-bottom: ${em(5)};
  }
`;

export const ManufacturerText = styled(_Text)`
  font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
  margin-bottom: 0;
`;
export const QuantityText = styled(_Text)`
  ${only(breakpointsKeys.MOBILE)} {
    line-height: normal;
  }
  margin-bottom: 0;
`;

export const AddToBasketInput = styled(_AddToBasketInput)`
  & .input {
    font-size: ${({ theme }) => theme.typography.fontSize.s2};
  }
  & .inputBox {
    height: ${rem(22)};
  }
  & .inputContainer {
    width: ${em(48)};
    max-width: ${em(48)};
  }
  & .plus,
  .minus {
    height: ${rem(20)};
    min-height: unset;
    width: ${rem(32)};
    border-radius: 30px;
    min-width: unset;
    ${({ theme }) => theme.helpers.transition(['background', 'color'])};
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
    border: 1px solid ${({ theme }) => theme.defaultPalette.monochrome[1]};
    color: ${({ theme }) => theme.addToBasketInput.controls.color.default};

    padding: ${rem(2)} ${rem(11)};
    .content {
      font-size: ${rem(16)};
    }

    &:not(:disabled):hover {
      background: ${(p) => p.theme.addToBasketInput.controls.bg.active};
      color: ${(p) => p.theme.addToBasketInput.controls.color.active};
    }
  }
`;
