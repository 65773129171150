import useTranslation from 'src/hooks/useTranslation';

import * as S from './styles.js';

const splitBySeparator = ':';

const defaultProps = {
  separateLines: false,
  lastPrice: '',
  displayPricesInLine: false,
};

export const LastPriceInfo = ({ lastPrice, className, separateLines, displayPricesInLine } = defaultProps) => {
  const { t } = useTranslation('tooltips');

  const [firstPart, ...prices] = lastPrice?.split(splitBySeparator);

  const secondPart = prices[0]?.split('<br/>').filter((string) => string !== ' ') ?? [''];

  const shouldDisplayInColumn = secondPart.length > 1;

  const tooltipMessage = t('lastPriceInfo');

  return (
    <S.LastPricesInfoWrapper className={className} shouldDisplayInColumn={shouldDisplayInColumn}>
      <S.FirstPart showSeparator={lastPrice} className="first-part">
        {firstPart}
      </S.FirstPart>
      {separateLines && <br />}
      {displayPricesInLine ? (
        <div className="second-part">
          {secondPart?.map((item, idx) => (
            <span key={idx} style={{ margin: '0 2px' }}>
              {item}
            </span>
          ))}
          <S.TooltipPopper trigger="mouseenter" title={tooltipMessage} showTooltipOnTouchDevices>
            {lastPrice ? <S.Icon icon="info" fill="#8e8e8e" svgSupportedViewBox="0 0 13 14" /> : null}
          </S.TooltipPopper>
        </div>
      ) : (
        secondPart.map((item, index) => {
          return (
            <S.SecondPart key={index} className="second-part">
              <S.SecondPartName>{item}</S.SecondPartName>
              <S.TooltipPopper trigger="mouseenter" title={tooltipMessage} showTooltipOnTouchDevices>
                {lastPrice ? <S.Icon icon="info" fill="#8e8e8e" svgSupportedViewBox="0 0 13 14" /> : null}
              </S.TooltipPopper>
            </S.SecondPart>
          );
        })
      )}
    </S.LastPricesInfoWrapper>
  );
};
