import * as S from './styles';

const getBasketPartsSelectionInfoNoteVariants = (variant) =>
  ({
    2: 'info',
  }[variant] ?? 'error');

export const BasketPartsSelectionInfoNote = ({ message = '', variant = 1 }) => {
  const colorVariant = getBasketPartsSelectionInfoNoteVariants(variant);

  return <S.Note variant={colorVariant}>{message}</S.Note>;
};
