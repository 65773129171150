import { useState, useEffect, useCallback, useRef } from 'react';
import GET_DELIVERY_COST_INFO_FOR_PRODUCT from 'src/graphql/queries/getDeliveryCostInfoForProduct.gql';
import useLazyShopQuery from '../useLazyShopQuery';
import useApiHit from '../useApiHit';

const prepareBasketSummaryMessageKey = (
  fullPackages,
  isProductInBasket,
  isDraftEqualToZeroValue,
  isDraftEqualToBasket,
  hasNoInfoAboutBasketQuantity,
  shouldShowRemovedBasketItemMessage,
) => {
  if (shouldShowRemovedBasketItemMessage) {
    return 'removedFromBasket';
  }

  if (!isDraftEqualToZeroValue && isDraftEqualToBasket) {
    return hasNoInfoAboutBasketQuantity ? 'productInBasketHtml' : 'totalValueHtml';
  }

  if (!isDraftEqualToZeroValue && !isDraftEqualToBasket && isProductInBasket) {
    return fullPackages ? '' : 'incomingTotalValueAfterUpdateHtml';
  }

  if (!isDraftEqualToZeroValue && !isDraftEqualToBasket && !isProductInBasket) {
    return fullPackages ? '' : 'incomingTotalValueHtml';
  }

  return '';
};

const calculateQuantity = (parts, draftQuantity, isProductInBasket, minQuantity, stepAmount) => {
  if (parts != null) {
    return !parts.length ? 0 : parts.reduce((quantity, part) => quantity + part.quantity, 0);
  }

  return draftQuantity || (isProductInBasket ? minQuantity : stepAmount);
};

export const useAddToBasketControls = ({
  nowInBasket = 0,
  zeroValue = 0,
  fullPackages,
  zeroPriceValue = 0,
  isLoading,
  isPartial,
  addToBasket,
  productId,
  step,
  recommendationId = null,
}) => {
  const [draftBasketQuantity, setDraftBasketQuantity] = useState(zeroValue);
  const [unsafeCurrentBasketQuantity, setCurrentBasketQuantity] = useState(nowInBasket || zeroValue);
  const [currentBasketPriceValue, setCurrentBasketPriceValue] = useState(zeroPriceValue);
  const [justRemovedFromBasket, setJustRemovedFromBasket] = useState(false);
  const [basketErrorsList, setBasketErrorsList] = useState([]);
  const [shouldShowRemovedBasketItemMessage, setShouldShowRemovedBasketItemMessage] = useState(false);
  const [removedBasketItemMessageClosed, setRemovedBasketItemMessageClosed] = useState(false);
  const [bestDeliveryInfo, setBestDeliveryInfo] = useState();
  const [getItemNewDeliveryInfo] = useLazyShopQuery(GET_DELIVERY_COST_INFO_FOR_PRODUCT, {
    onCompleted: ({ deliveryCostInfo }) => {
      setBestDeliveryInfo(deliveryCostInfo);
    },
  });
  const { hit } = useApiHit();
  const debounceRef = useRef();

  const isProductInBasket = unsafeCurrentBasketQuantity > zeroValue;
  const currentBasketQuantity = unsafeCurrentBasketQuantity || zeroValue;
  const isDraftEqualToBasket = draftBasketQuantity === unsafeCurrentBasketQuantity;
  const isDraftEqualToZeroValue = draftBasketQuantity === zeroValue;
  const isCurrentEqualToZeroValue = currentBasketQuantity === zeroValue;
  const hasNoInfoAboutBasketQuantity = currentBasketPriceValue === zeroPriceValue;
  const isDraftQuantityEqualToBasketQuantity = isDraftEqualToBasket && isProductInBasket;

  const handleChangeItemQuantity = (quantity) => {
    clearTimeout(debounceRef.current);

    debounceRef.current = setTimeout(() => {
      getItemNewDeliveryInfo({
        variables: {
          productid: productId,
          quantity: quantity,
        },
      });
    }, 500);
  };

  useEffect(() => {
    if (justRemovedFromBasket && isDraftEqualToZeroValue && isCurrentEqualToZeroValue) {
      setShouldShowRemovedBasketItemMessage(true);
      setRemovedBasketItemMessageClosed(false);
      setJustRemovedFromBasket(false);
    }
  }, [justRemovedFromBasket, isDraftEqualToZeroValue, isCurrentEqualToZeroValue]);

  useEffect(() => {
    if (!justRemovedFromBasket) {
      if (!isDraftEqualToZeroValue) {
        if (!removedBasketItemMessageClosed) {
          setRemovedBasketItemMessageClosed(true);
        } else {
          setShouldShowRemovedBasketItemMessage(false);
        }
      }
    }
  }, [justRemovedFromBasket, isDraftEqualToZeroValue, removedBasketItemMessageClosed]);

  const handleProductAddToBasketButtonClick = useCallback(
    async (
      { parts, before, after } = {
        parts: null,
        before: () => null,
        after: () => null,
      },
    ) => {
      if (isLoading) return;

      // NOTE: https://jira.unity.pl/browse/ONNINB2B-982 point 3.
      // if (isDraftQuantityEqualToBasketQuantity) {
      // if (!isPartial) return;
      if (before) {
        before();
      }
      // }

      setBasketErrorsList([]);

      if (recommendationId) {
        hit({
          rid: recommendationId,
          productid: productId,
        });
      }

      const quantity = calculateQuantity(parts, draftBasketQuantity, isProductInBasket, zeroValue, step);
      handleChangeItemQuantity(quantity);

      if (quantity === zeroValue) {
        setJustRemovedFromBasket(true);
      }

      const basketData = await addToBasket({
        variables: { input: { productid: productId, parts, quantity } },
      });

      if (basketData) {
        setCurrentBasketQuantity(basketData?.data?.addToBasket?.added?.quantity || zeroValue);
        setCurrentBasketPriceValue(basketData?.data?.addToBasket?.added?.value || 0);
        setBasketErrorsList(basketData?.data?.addToBasket?.errors ? basketData.data.addToBasket.errors : []);
      }

      if (after) {
        after();
      }

      return basketData;
    },
    [addToBasket, draftBasketQuantity, hit, isLoading, isProductInBasket, productId, recommendationId, step, zeroValue],
  );

  const basketSummaryMessageKey = prepareBasketSummaryMessageKey(
    fullPackages,
    isProductInBasket,
    isDraftEqualToZeroValue,
    isDraftEqualToBasket,
    hasNoInfoAboutBasketQuantity,
    shouldShowRemovedBasketItemMessage,
  );

  return {
    isProductInBasket,
    isDraftEqualToBasket,
    isDraftEqualToZeroValue,
    isDraftQuantityEqualToBasketQuantity,
    basketSummaryMessageKey,
    draftBasketQuantity,
    currentBasketQuantity,
    currentBasketPriceValue,
    justRemovedFromBasket,
    basketErrorsList,
    setDraftBasketQuantity,
    setCurrentBasketQuantity,
    setCurrentBasketPriceValue,
    setJustRemovedFromBasket,
    setBasketErrorsList,
    handleProductAddToBasketButtonClick,
    handleChangeItemQuantity,
    updatedBestDeliveryInfo: bestDeliveryInfo?.bestdel,
  };
};
