import { createNextLinkProps } from 'src/utils/createNextLinkProps';
import { onnTopMarkersMap } from 'src/enums/onnTopMarkers';
import useTranslation from 'src/hooks/useTranslation';
import Link from '../Link';
import TooltipPopper from '../TooltipPopper';

import * as S from './styles';

const defaultProps = {
  position: 'bottom',
  onntop: 0,
  onntopcb: 0,
  isSmall: false,
};

const getOnnTopMarkersEnumValue = (onnTopKey) => onnTopMarkersMap.get(onnTopKey);

export const OnnTopMarkers = ({
  onnTopKey,
  cashbackKey,
  position,
  onnTopHomePageLayout,
  onnTopHomePageSlug,
  isSmall,
  ...restProps
} = defaultProps) => {
  const { t } = useTranslation();

  const onnTopValue = getOnnTopMarkersEnumValue(onnTopKey);
  const additionalLinkProps = createNextLinkProps(onnTopHomePageSlug, onnTopHomePageLayout);

  return (
    <S.OnnTopMarkerContainer {...restProps}>
      {onnTopValue ? (
        <Link blank {...additionalLinkProps}>
          <TooltipPopper title={t(`${onnTopValue}Marker`)} placement={position} trigger="mouseenter">
            <S.OnnTopMarkerImage
              alt={t(`${onnTopValue}Marker`)}
              src={t('onnTopMarker', { onnTopValue: onnTopValue })}
              isSmall={isSmall}
            />
          </TooltipPopper>
        </Link>
      ) : null}
      {cashbackKey ? (
        <Link blank {...additionalLinkProps}>
          <TooltipPopper title={t(`cashbackMarker`)} placement={position} trigger="mouseenter">
            <S.OnnTopMarkerImage alt={t(`cashbackMarker`)} src={t('onnTopCashback')} isSmall={isSmall} />
          </TooltipPopper>
        </Link>
      ) : null}
    </S.OnnTopMarkerContainer>
  );
};

OnnTopMarkers.defaultProps = defaultProps;
