import GET_BASKET_PARTS from 'src/graphql/mutations/getBasketParts.gql';
import useRESTMutation from '../useRESTMutation';

export const useBasketParts = ({ productId, onlyLocal }) => {
  const [getBasketParts, getBasketPartsHookData] = useRESTMutation(GET_BASKET_PARTS, {
    variables: { productId, onlyLocal },
  });

  return [getBasketParts, getBasketPartsHookData];
};
