import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import useCurrency from '../useCurrency';

export const useAnalytics = () => {
  const [testMode] = useState(false);

  const [currencyInfo] = useCurrency({
    fetchPolicy: 'cache-first',
  });
  const { pathname } = useRouter();
  const { currentCurrency } = currencyInfo;

  const showDataLayerLog = () => {
    console.debug(window.dataLayer);
  };

  const getPageType = useCallback(() => {
    switch (pathname) {
      case '/basketType':
      case '/basketPage':
        return 'cart';
      case '/mainCategoriesListPage':
      case '/brandPage':
      case '/seriesPage':
        return 'category';
      case '/searchPage':
        return 'search result';
      case '/productPage':
        return 'product page';
      default:
        return '';
    }
  }, [pathname]);

  const addDataLayer = useCallback(
    (input) => {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(input);
      if (testMode) {
        showDataLayerLog();
      }
    },
    [testMode],
  );

  const onProductDetailView = useCallback(
    (product) => {
      try {
        if (!product) {
          return;
        }
        const { card, categorypath } = product;

        if (!card) {
          return;
        }
        const { index, name, catalogindex: catalogIndex, price, brand, avail } = card;
        addDataLayer({
          event: 'eec.detail',
          ecommerce: {
            currencyCode: currentCurrency.name,
            detail: {
              actionField: {
                list: getPageType(),
              },
              products: [
                {
                  id: index,
                  name,
                  category: categorypath[categorypath.length - 1].name,
                  sku: catalogIndex,
                  price: price.items[0].priceseo,
                  brand: brand[0]?.name,
                  quantity: 1,
                  stock_level: avail.quantitydc,
                },
              ],
            },
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.detail failed!', e);
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, getPageType, testMode],
  );

  const onAddToBasket = useCallback(
    ({ id, name, category, sku, price, brand, quantity }) => {
      try {
        addDataLayer({
          event: 'eec.add',
          ecommerce: {
            currencyCode: currentCurrency.name,
            add: {
              actionField: {
                list: getPageType(),
              },
              products: [
                {
                  id,
                  name,
                  category,
                  sku,
                  price,
                  brand,
                  quantity,
                },
              ],
            },
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.add failed!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, getPageType, testMode],
  );

  const onRemoveFromBasket = useCallback(
    ({ id, name, category, sku, price, brand, quantity }) => {
      try {
        addDataLayer({
          event: 'eec.remove',
          ecommerce: {
            currencyCode: currentCurrency.name,
            remove: {
              actionField: {
                list: getPageType(),
              },
              products: [
                {
                  id,
                  name,
                  category,
                  sku,
                  price,
                  brand,
                  quantity,
                },
              ],
            },
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.remove failed!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, getPageType, testMode],
  );

  const onBasketView = useCallback(
    (inputProducts) => {
      const products =
        inputProducts?.map(({ productId, productName, productInfo, price, quantity }) => ({
          id: productInfo?.index,
          name: productName,
          category: productInfo?.categories?.[0]?.name ?? '',
          sku: productInfo?.catalogindex,
          price,
          brand: productInfo?.brand?.[0]?.name ?? '',
          quantity,
        })) || [];

      try {
        addDataLayer({
          event: 'eec.checkout',
          ecommerce: {
            currencyCode: currentCurrency.name,
            checkout: {
              actionField: {
                step: 1,
                option: 'Koszyk',
              },
              products,
            },
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.checkout failed on step 1!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, testMode],
  );

  const onDeliveryView = useCallback(
    (inputProducts) => {
      const products =
        inputProducts?.map(({ productId, productName, productInfo, price, quantity }) => ({
          id: productInfo?.index,
          name: productName,
          category: productInfo?.categories?.[0].name,
          sku: productInfo?.catalogindex,
          price,
          brand: productInfo?.brand?.[0].name,
          quantity,
        })) || [];

      try {
        addDataLayer({
          event: 'eec.checkout',
          ecommerce: {
            currencyCode: currentCurrency.name,
            checkout: {
              actionField: {
                step: 2,
                option: 'Dostawa i płatność',
              },
              products,
            },
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.checkout failed on step 2!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, testMode],
  );

  const addAnalyticsOnProductList = useCallback(
    (products) => {
      try {
        const list = getPageType();
        addDataLayer({
          event: 'eec.impressionView',
          ecommerce: {
            currencyCode: currentCurrency.name,
            impressions: [
              ...products
                .slice(0, 10)
                .map(({ availability, catalogIndex, categoryName, sapIndex, price, producerName, productName }, 
                  index) => ({
                  id: sapIndex,
                  name: productName,
                  list,
                  category: categoryName,
                  position: index,
                  sku: catalogIndex.value,
                  price: price.prices[0].seoPrice,
                  brand: producerName,
                  quantity: availability?.mainVolume,
                })),
            ],
          },
        });
      } catch (e) {
        console.debug('Add data layer eec.impressionView failed!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, getPageType, testMode],
  );

  const addAnalyticsOnSignUpAndIn = useCallback(
    ({ customerId = null, event = 'signup', userType = null, customer = null }) => {
      try {
        addDataLayer({
          event,
          customerId,
          userType,
          customer,
        });
      } catch (e) {
        console.debug(`Add data layer ${event} failed!`);
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, testMode],
  );

  const addAnalyticsOnPurchase = useCallback(
    ({ ordersList, transactionType, getDeliveryCost, customer = null }) => {
      try {
        ordersList.forEach((order) => {
          addDataLayer({
            event: 'eec.purchase',
            ecommerce: {
              currencyCode: currentCurrency.name,
              purchase: {
                actionField: {
                  id: order.docnumber,
                  revenue: order.valuegross,
                  affiliation: order.deliverytypename,
                  tax: (order.valuegross - order.valuenetto).toFixed(2),
                  shipping: order?.deliverycost,
                  transactionType,
                },
                products: order.lines.map((product) => ({
                  id: product.index,
                  name: product.productname,
                  category: product?.productinfo?.categories?.[0].name || '',
                  sku: product.catalogindex,
                  price: product.price,
                  brand: product?.productinfo?.brand?.[0].name || '',
                  quantity: product.quantity,
                }))
              }
            },
            customer,
          })
        })
        // TODO when new basket purchase method is ok remove this
        
        // const combinedProductsList = [];
        // const ordersNumberList = [];
        // const valueGrossList = [];
        // const valueNettoList = [];
        // const combinedDeliveryTypeName = [];

        // ordersList.forEach((orderItem) => {
        //   ordersNumberList.push(orderItem.docnumber);
        //   valueGrossList.push(orderItem.valuegross);
        //   valueNettoList.push(orderItem.valuenetto);
        //   combinedDeliveryTypeName.push(orderItem.deliverytypename);
        //   combinedProductsList.push(...orderItem?.lines);
        // });

        // const sumValueGross = valueGrossList.reduce((acc, val) => acc + val, 0);
        // const sumValueNetto = valueNettoList.reduce((acc, val) => acc + val, 0);
        // addDataLayer({
        //   event: 'eec.purchase',
        //   ecommerce: {
        //     currencyCode: currentCurrency.name,
        //     purchase: {
        //       actionField: {
        //         id: ordersNumberList.join('-'),
        //         revenue: sumValueGross,
        //         affiliation: combinedDeliveryTypeName.join('-'),
        //         tax: (sumValueGross - sumValueNetto).toFixed(2),
        //         shipping: getDeliveryCost(true),
        //         transactionType,
        //       },
        //       products: combinedProductsList.map((product) => ({
        //         id: product.index,
        //         name: product.productname,
        //         category: product?.productinfo?.categories?.[0].name || '',
        //         sku: product.catalogindex,
        //         price: product.price,
        //         brand: product?.productinfo?.brand?.[0].name || '',
        //         quantity: product.quantity,
        //       })),
        //     },
        //   },
        //   customer,
        // });
      } catch (e) {
        console.debug('Add data layer eec.purchase failed!');
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, currentCurrency, testMode],
  );

  const addAnalyticsOnRegistrationStep = useCallback(
    ({ userType = null, stepId = null }) => {
      try {
        addDataLayer({
          event: 'registration',
          userType,
          stepId,
        });
      } catch (e) {
        console.debug(`Add data layer registration failed!`);
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, testMode],
  );

  const addAnalyticsOnRegistrationStepNewForm = useCallback(
    (registerData) => {
      try {
        addDataLayer(registerData);
      } catch (e) {
        console.debug(`Add data layer registration failed!`);
        if (testMode) {
          console.debug(e);
        }
      }
    },
    [addDataLayer, testMode],
  );

  return {
    onProductDetailView,
    onAddToBasket,
    onRemoveFromBasket,
    onBasketView,
    onDeliveryView,
    addAnalyticsOnProductList,
    addAnalyticsOnSignUpAndIn,
    addAnalyticsOnPurchase,
    addAnalyticsOnRegistrationStep,
    addAnalyticsOnRegistrationStepNewForm,
  };
};
