import styled from 'styled-components';
import { rem } from 'polished';
import { up } from 'styled-breakpoints';
import { breakpointsKeys } from 'src/enums/breakpoints';

export const OnnTopMarkerImage = styled.img`
  height: ${({ isSmall }) => (isSmall ? rem(20) : rem(35))};

  ${up(breakpointsKeys.DESKTOP)} {
    height: ${({ isSmall }) => (isSmall ? rem(28) : rem(35))};
  }
`;

export const OnnTopMarkerContainer = styled.div`
  display: flex;
  align-items: center;
  max-height: ${rem(40)};

  ${up('tablet')} {
    max-height: auto;
  }

  & > a:nth-of-type(2) {
    div {
      margin-left: ${rem(12)};

      ${up('tablet')} {
        margin-left: ${rem(10)};
      }
    }
  }
`;
