import styled, { css } from 'styled-components';
import SelectComponent from '../Select';

export const PriceCurrencySelect = styled.span`
  display: flex;
  flex-direction: ${({ invertSelects }) => (invertSelects ? 'row-reverse' : 'row')};

  ${({ invertSelects }) => {
    if (invertSelects) {
      return css`
        & > span:last-of-type {
          margin-right: 5px;
        }
      `;
    }

    return css`
      & > span:first-of-type {
        margin-right: 5px;
      }
    `;
  }};
`;

export const Select = styled(SelectComponent)`
  button > .inner > svg {
    color: ${({ theme }) => theme.defaultPalette.blazeOrange[0]};
  }
`;
