import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { em } from 'polished';

import ImportedTooltipPopper from 'src/components/TooltipPopper';
import ImportedIcon from 'src/components/Icon';

import { breakpointsKeys } from 'src/enums/breakpoints';

const generateStylesForDisplayingInColumn = () => css`
  display: flex;
  flex-direction: column;
`;

export const LastPricesInfoWrapper = styled.div`
  text-align: center;

  color: ${({ theme }) => theme.productCardSummary.color.lastPricesInfo};
  font-size: ${({ theme }) => theme.productCardSummary.fontSize.lastPricesInfo};
  line-height: ${em(20)};
  ${({ shouldDisplayInColumn }) => shouldDisplayInColumn && generateStylesForDisplayingInColumn()};
  ${up(breakpointsKeys.TABLET)} {
    margin-top: ${em(5)};
    white-space: nowrap;
  }
`;

export const TooltipPopper = styled(ImportedTooltipPopper)`
  display: inline;
  ${up(breakpointsKeys.TABLET)} {
    align-self: baseline;
  }
`;

export const Icon = styled(ImportedIcon)`
  font-size: ${({ theme }) => theme.productCardSummary.fontSize.lastPriceInfoIcon};
  margin-bottom: ${em(3)};
  cursor: help;
`;

export const FirstPart = styled.div`
  display: inline;
  ${({ showSeparator }) =>
    showSeparator &&
    css`
      &:after {
        content: ':';
      }
    `}
`;
export const SecondPart = styled.div`
  display: inline;
`;
export const SecondPartName = styled.p`
  display: inline;
  margin-right: ${em(3)};
`;
