import { useEffect } from 'react';
import POST_HIT from 'src/graphql/mutations/postHit.gql';
import useRESTMutation from '../useRESTMutation';

export const useApiHit = (onError = () => null, onSuccess = () => null) => {
  const [executeRequest, { loading, data }] = useRESTMutation(POST_HIT, {
    onError,
  });

  useEffect(() => {
    if (loading === false && data) {
      onSuccess(data);
    }
  }, [data, loading, onSuccess]);

  const hit = (input) => executeRequest({ variables: { input } });

  return { hit };
};
