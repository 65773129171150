export const onnTopMarkersKeys = {
  WHITE: 'white',
  BLUE: 'blue',
  SILVER: 'silver',
  GOLD: 'gold',
};

export const onnTopMarkersMap = new Map([
  [1, onnTopMarkersKeys.WHITE],
  [2, onnTopMarkersKeys.BLUE],
  [3, onnTopMarkersKeys.SILVER],
  [4, onnTopMarkersKeys.GOLD],
]);
