// NOTE: This install with simplebar-react
// eslint-disable-next-line import/no-extraneous-dependencies
import 'simplebar/src/simplebar.css';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import memoize from 'fast-memoize';
import { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { nanoid } from 'nanoid';
import useTranslation from 'src/hooks/useTranslation';
import { cuttingsMap, cuttingsKeys } from 'src/enums/cuttings';
import { ceilToNearest } from 'src/utils/ceilToNearest';
import ErrorMessageText from 'src/components/Text';
import { productMinQuantity } from 'config/basket';
import TooltipPopper from 'src/components/TooltipPopper';
import { query } from 'config/queries';
import useCoreRoutesInfo from 'src/hooks/useCoreRoutesInfo';
import { prepareQuery as prepareFilterQuery } from 'src/utils/prepareQuery.js';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import { BasketPartsSelectionInfoNote } from './BasketPartsSelectionInfoNote';
import * as S from './styles';
import { BasketPartSelectionPriceList } from './BasketPartSelectionPriceList';
import useBreakpointDetector from 'src/hooks/useBreakpointDetector';
import { breakpointsKeys } from 'src/enums/breakpoints';
import { uiComponentVariant } from 'src/enums/uiComponentVariant';

const BASKET_PART_TO_ORDER_TEMPLATE_ID_DELIMITER = '_';

const Modal = dynamic(() => import('../Modal'), {
  ssr: false,
});

const Table = dynamic(() => import('../Table'), {
  ssr: false,
});

const defaultProps = {
  children: null,
};

const createPartId = (id, partIdx, isTemplate) =>
  `${isTemplate ? `${partIdx}${BASKET_PART_TO_ORDER_TEMPLATE_ID_DELIMITER}` : ''}${id}`;

const prepareQuantitiesListBeforeRequest = (partsQuantitiesList) =>
  partsQuantitiesList.reduce((list, { quantity, id, isTemplate }) => {
    if (quantity) {
      list.push({
        id: isTemplate ? id.split(BASKET_PART_TO_ORDER_TEMPLATE_ID_DELIMITER)[1] + nanoid(31) : id,
        quantity,
      });
    }
    return list;
  }, []);

const renderAddNextButtonWrapper = (mobileVersion, addNextPartLabel, handleAddNextPartButtonClick) => (
  <S.AddNextButtonWrapper mobileVersion={mobileVersion}>
    <S.ControlsLabel onClick={handleAddNextPartButtonClick}>{addNextPartLabel}</S.ControlsLabel>
    <S.ControlsButton isRounded onClick={handleAddNextPartButtonClick}>
      +
    </S.ControlsButton>
  </S.AddNextButtonWrapper>
);

export const BasketPartsSelection = ({
  parts,
  productName,
  productImgSrc,
  addToBasketInputLabel,
  onAddToBasket,
  busy,
  children,
  basketPartsPriceSectionEntries = {},
  message = '',
} = defaultProps) => {
  const scrollBarRef = useRef();

  const validationErrorNotes = useRef(new Set());
  const validationErrorDismissedRef = useRef(true);
  const currentInputErrorCleared = useRef(false);
  const router = useRouter();
  const { asPath, route } = router;
  const [, setForceUpdateState] = useState(0);
  const { t } = useTranslation();
  const { t: tSearch } = useTranslation('searchfilter');
  const { t: tToolTips } = useTranslation('tooltips');
  const transformPrice = useCurrentCurrency({
    sign: basketPartsPriceSectionEntries?.price?.currsig,
  });

  const currentBreakpoint = useBreakpointDetector();
  const isMobile = currentBreakpoint === breakpointsKeys.MOBILE;
  const pageKey = tSearch('page');
  const [partsQuantitiesList, setPartsQuantitiesList] = useState([]);
  const [hasAddMoreToOrderButton, setHasAddMoreToOrderButton] = useState(false);
  const [additionalToOrderEntriesSize, setAdditionalToOrderEntriesSize] = useState(0);
  const [isOpen, setIsOpen] = useState(false);

  const brandNameEntries = basketPartsPriceSectionEntries?.brand?.[0];
  const seriesEntries = basketPartsPriceSectionEntries?.series?.[0];
  const isBrandNameAvailable = !!brandNameEntries;
  const isSeriesAvailable = !!seriesEntries;

  const coreRoutesInfo = useCoreRoutesInfo();

  const productsListPagesLayout = {
    searchPage: `/${coreRoutesInfo?.searchPage?.layout}`,
    brandPage: `/${coreRoutesInfo?.brandPage?.layout}`,
    productsPage: `/${coreRoutesInfo?.productsPage?.layout}`,
    seriesPage: `/${coreRoutesInfo?.seriesPage?.layout}`,
  };

  const forceUpdate = () => setForceUpdateState((c) => (c === 0 ? 1 : 0));
  const getUnitProp = () => {
    const unitProp = parts.find((item) => !!item.unit);

    return unitProp.unit;
  };

  const removePaginationFromPath = (path) => {
    const paginationRegexPattern = new RegExp(`/${pageKey}:[0-9]`, 'g');
    const pathWithoutPaginationFilter = path.replace(paginationRegexPattern, '');

    return pathWithoutPaginationFilter;
  };

  const normalizeFiltersPath = (filterName, value) => {
    const queryPrefix = `?${query}=`;
    if (!asPath.includes(queryPrefix)) {
      return removePaginationFromPath(
        `${asPath.replace('/', '')}${queryPrefix}/${filterName}:${prepareFilterQuery(value)}`,
      );
    }

    if (!asPath.includes(value)) {
      return removePaginationFromPath(`${asPath.replace('/', '')}/${filterName}:${prepareFilterQuery(value)}`);
    }

    const regExp = new RegExp(`\/${filterName}:([^\/]*)`);
    const filterListFromQuery = regExp.exec(asPath)[1].split(',');

    if (filterListFromQuery.indexOf(value) === -1) {
      filterListFromQuery.push(prepareFilterQuery(value));
    }

    return removePaginationFromPath(asPath.replace(regExp, `/${filterName}:${filterListFromQuery.join(',')}`));
  };

  const closeModal = () => {
    setIsOpen(false);
    setAdditionalToOrderEntriesSize(0);
    setHasAddMoreToOrderButton(false);
    validationErrorNotes.current = new Set();
    validationErrorDismissedRef.current = true;
    currentInputErrorCleared.current = false;
  };

  const redirectToNewFilterPath = (filterPath) => {
    const PRODUCTS_LIST_PAGES_LAYOUT = Object.keys(productsListPagesLayout).find(
      (layout) => productsListPagesLayout[layout] === route,
    );

    router.push(productsListPagesLayout[PRODUCTS_LIST_PAGES_LAYOUT], filterPath);
    closeModal();
  };

  const handleFilterByBrandFromItem = () => {
    const normalizedFilterPath = normalizeFiltersPath(brandNameEntries.filtername, brandNameEntries.slug);
    redirectToNewFilterPath(normalizedFilterPath);
  };

  const handleFilterBySeriesFromItem = () => {
    const normalizedFilterPath = normalizeFiltersPath(seriesEntries.filterName, seriesEntries.slug);
    redirectToNewFilterPath(normalizedFilterPath);
  };

  const totalQuantity = partsQuantitiesList.reduce((quantitySum, { quantity }) => quantitySum + Number(quantity), 0);
  const isAnyPartInBasket = parts.some(({ qtyinbasket }) => qtyinbasket > 0);
  const isModal = !!children;

  const loadingLabel = t('updatingBasket');
  const mustStayLabel = t('mustStay');
  const partMaxToTakeLabel = t('partMaxToTake');
  const extendedDeliveryDateLabel = t('extendedDeliveryDate');
  const addNextPartLabel = t('addNextPart');

  const isPartDiscountColumnAvailable = parts.find(({ partdiscount }) => !!partdiscount);

  const isPriceSectionAvailable = basketPartsPriceSectionEntries?.price?.items?.find(({ priceend }) => priceend > 0);

  const colsWithTranslatedLabels = [
    {
      key: 'manufacturer',
      title: t('partManufacturer'),
      align: 'center',
      bold: true,
      showLabel: true,
      isHeadElement: true,
    },
    {
      key: 'quantity',
      title: t('partLength'),
      align: 'center',
      bold: false,
      showLabel: false,
      isHeadElement: false,
    },
    {
      key: 'partdesc',
      title: t('partDescription'),
      align: 'center',
      bold: false,
      showLabel: true,
      isHeadElement: false,
    },
    {
      key: 'partDiscount',
      title: t('partDiscount'),
      align: 'center',
      bold: false,
      showLabel: true,
      isHeadElement: false,
    },
    {
      key: 'select',
      title: t('partSelect'),
      align: 'center',
      bold: false,
      showLabel: true,
      isHeadElement: false,
    },
  ];

  const compileColsWithTranslatedLabels = () => {
    const keysToRemove = { partDiscount: !isPartDiscountColumnAvailable && !isPriceSectionAvailable };

    return colsWithTranslatedLabels.filter((col) => keysToRemove[col.key] !== true);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const createMemoizedPartsQuantitiesListSetter = useMemo(
    () =>
      memoize((partId, isTemplate) => (partQuantity) =>
        setPartsQuantitiesList((quantitiesList) => {
          const quantitiesListDraft = [...quantitiesList];
          const partIndex = quantitiesList.findIndex(({ id }) => id === partId);
          const shouldAddPart = partQuantity > 0;

          if (shouldAddPart) {
            const partEntry = { id: partId, quantity: partQuantity, isTemplate };

            if (partIndex === -1) {
              quantitiesListDraft.push(partEntry);
            } else {
              quantitiesListDraft[partIndex] = partEntry;
            }
          }

          if (!shouldAddPart && partIndex > -1) {
            quantitiesListDraft.splice(partIndex, 1);
          }

          return quantitiesListDraft;
        }),
      ),
    [],
  );

  const handleAddToBasketButtonClick = useCallback(
    () =>
      onAddToBasket(
        partsQuantitiesList.length ? prepareQuantitiesListBeforeRequest(partsQuantitiesList) : [],
        closeModal,
      ),
    [onAddToBasket, partsQuantitiesList],
  );

  const renderContent = useCallback(() => {
    let lastTemplateId = null;

    if (parts.length === 0 && !!message) {
      return <ErrorMessageText variant={uiComponentVariant.SEPTENARY}>{message}</ErrorMessageText>;
    }
    if (parts.length === 0) {
      return null;
    }

    return (
      <S.BasketPartsSelection limitWidth={isModal}>
        <S.CenteredTextSection>
          <S.Heading variant="secondary">{t('selectParts')}:</S.Heading>
        </S.CenteredTextSection>

        <S.Header>
          <S.HeaderAndImgWrapper>
            <S.ProductImg src={productImgSrc} alt={productName} />

            <div>
              <S.ProductName variant="secondary" component="h2">
                {productName}
              </S.ProductName>
              {!isMobile && (
                <>
                  <S.NameAndSeriesFilterWrapper>
                    {isBrandNameAvailable && (
                      <TooltipPopper
                        title={tToolTips('displayProductsWithBrandFilter')}
                        trigger="mouseenter"
                        hasMaxWidth={false}
                        useBrowserTooltip
                      >
                        <S.BrandAndSeriesFilter className="content" onClick={handleFilterByBrandFromItem}>
                          {brandNameEntries?.name}
                        </S.BrandAndSeriesFilter>
                      </TooltipPopper>
                    )}
                    {isSeriesAvailable && (
                      <TooltipPopper
                        title={tToolTips('displayProductsWithSeriesFilter')}
                        trigger="mouseenter"
                        hasMaxWidth={false}
                        useBrowserTooltip
                      >
                        <S.BrandAndSeriesFilter className="content" onClick={handleFilterBySeriesFromItem}>
                          {seriesEntries?.name}
                        </S.BrandAndSeriesFilter>
                      </TooltipPopper>
                    )}
                  </S.NameAndSeriesFilterWrapper>
                  <S.NameAndSeriesFilterWrapper>
                    <S.IndexText component="span" variant="quinary" fontSizeIndex={4}>
                      <TooltipPopper title={tToolTips('listPageBrandIndex')} trigger="mouseenter" useBrowserTooltip>
                        {basketPartsPriceSectionEntries?.catalogindex}
                      </TooltipPopper>
                    </S.IndexText>

                    <S.IndexText component="span" variant="quinary" fontSizeIndex={4}>
                      <TooltipPopper title={tToolTips('listPageOnninenIndex')} trigger="mouseenter" useBrowserTooltip>
                        {basketPartsPriceSectionEntries?.index}
                      </TooltipPopper>
                    </S.IndexText>
                  </S.NameAndSeriesFilterWrapper>
                </>
              )}

              {Boolean(isMobile && !!Object.keys(basketPartsPriceSectionEntries).length) && (
                <BasketPartSelectionPriceList {...basketPartsPriceSectionEntries?.price} unit={getUnitProp()} />
              )}
            </div>
          </S.HeaderAndImgWrapper>

          {Boolean(!isMobile && !!Object.keys(basketPartsPriceSectionEntries).length) && (
            <BasketPartSelectionPriceList {...basketPartsPriceSectionEntries?.price} unit={getUnitProp()} />
          )}
        </S.Header>

        <S.SimpleBar ref={scrollBarRef}>
          <Table
            mobileVariant="secondary"
            desktopVariant="secondary"
            skipChildrenBorder
            extendCellIfNextIsEmpty
            cols={compileColsWithTranslatedLabels()}
            records={parts

              .reduce((partsWithTemplates, partRecord) => {
                if (partRecord.parttemplate && additionalToOrderEntriesSize > 0) {
                  const toOrderRecordClones = [];

                  for (let i = 0; i < additionalToOrderEntriesSize; i += 1) {
                    toOrderRecordClones.push({ ...partRecord, id: partRecord.id + i });
                  }

                  return [...partsWithTemplates, partRecord, ...toOrderRecordClones];
                }

                return [...partsWithTemplates, partRecord];
              }, [])
              .map((part, partIdx) => {
                const {
                  manufacturer,
                  id,
                  unit,
                  cutting,
                  partdesc,
                  parttemplate,
                  allocation,
                  quantity: availableQuantity,
                  qtyinbasket: nowInBasket,
                  quantitymod: quantityModulo,
                  muststay: mustStay,
                  cuttingdesc: cuttingCostDescription,
                  cuttingfreefrom: cuttingFreeFrom,
                  partdiscount: partDiscount,
                  price,
                } = part;

                const isTemplate = !!parttemplate;
                const isLastTemplateRow = isTemplate && partIdx + 1 === parts.length + additionalToOrderEntriesSize;
                const formattedId = createPartId(id, partIdx, isTemplate);
                // TODO: Enum would come in handy here
                const isForOrderPart = allocation === 4;

                if (isTemplate && !hasAddMoreToOrderButton) {
                  setHasAddMoreToOrderButton(true);
                }

                if (isLastTemplateRow) {
                  lastTemplateId = formattedId;
                }

                const partEntry = partsQuantitiesList.find((entry) => entry.id === formattedId);

                const shouldDisplayCuttingCostMessage =
                  price * partEntry?.quantity < cuttingFreeFrom && partEntry?.quantity !== availableQuantity;

                const hasDraftQuantity = !!partEntry?.quantity;
                const isQuantityTouched = hasDraftQuantity && partEntry.quantity !== availableQuantity;
                const isToCut = cuttingsMap.get(cutting) === cuttingsKeys.TO_CUT;
                const isAsAWhole = cuttingsMap.get(cutting) === cuttingsKeys.AS_A_WHOLE;
                const maxToTake = isToCut ? availableQuantity - mustStay : availableQuantity;
                const showAddMoreToOrderBtn = hasAddMoreToOrderButton && isTemplate && formattedId === lastTemplateId;
                const partQuantitySetter = createMemoizedPartsQuantitiesListSetter(formattedId, isTemplate);
                const handleCheckboxToggle = () => partQuantitySetter(hasDraftQuantity ? 0 : availableQuantity);
                const handleAddNextPartButtonClick = () => setAdditionalToOrderEntriesSize((size) => size + 1);

                const manufacturerCell = showAddMoreToOrderBtn ? (
                  <div>
                    <S.ManufacturerText>{manufacturer}</S.ManufacturerText>
                    {renderAddNextButtonWrapper(false, addNextPartLabel, handleAddNextPartButtonClick)}
                  </div>
                ) : (
                  <S.ManufacturerText>{manufacturer}</S.ManufacturerText>
                );

                const beforeUpdateValueTransformer = (value, isCausedByIncDecButton, onBeforeTransform) => {
                  let transformedValue = value;

                  if (!isForOrderPart && isToCut && value !== availableQuantity) {
                    if (isCausedByIncDecButton && value === availableQuantity - quantityModulo) {
                      transformedValue = maxToTake;
                    } else {
                      transformedValue = value > maxToTake ? availableQuantity : value;
                    }
                  }

                  if (onBeforeTransform && typeof onBeforeTransform === 'function') {
                    onBeforeTransform();
                  }

                  return ceilToNearest(transformedValue, quantityModulo);
                };

                let errorNote = '';
                let errorNoteVariant = null;
                let infoNote = '';

                const showMustStayNote =
                  !isForOrderPart && isToCut && isQuantityTouched && partEntry.quantity > maxToTake;
                const showMaxToTakeLabel = isForOrderPart && isQuantityTouched && partEntry.quantity > maxToTake;

                if (shouldDisplayCuttingCostMessage) {
                  infoNote = cuttingCostDescription;
                  errorNoteVariant = 2;
                }

                if (showMustStayNote) {
                  errorNoteVariant = 1;
                  errorNote = `${mustStayLabel} ${mustStay} ${unit}`;
                }

                if (showMaxToTakeLabel) {
                  errorNoteVariant = 1;
                  errorNote = `${partMaxToTakeLabel} ${maxToTake} ${unit}`;
                }

                if (errorNote) {
                  validationErrorNotes.current.add(formattedId);
                  infoNote = '';
                  validationErrorDismissedRef.current = false;
                } else {
                  validationErrorNotes.current.delete(formattedId);
                }

                const shouldRenderInfoNote = errorNote || infoNote;

                const getPriceColValue = () => {
                  const threshold = basketPartsPriceSectionEntries?.price.items.reduce((acc, curr) => {
                    if (curr.quantity <= Number(partEntry?.quantity || 1)) {
                      acc = curr.priceend;
                    }
                    return acc;
                  }, basketPartsPriceSectionEntries?.price.items?.[0]?.priceend);

                  const transformedPrice = `${transformPrice(threshold)}/${unit}`;
                  return transformedPrice;
                };

                const compilePartDiscountCol = () => {
                  if (isForOrderPart) {
                    return undefined;
                  }
                  if (partDiscount) {
                    return `${transformPrice(price)}/${unit}`;
                  }
                  return getPriceColValue();
                };
                return {
                  id,
                  children: shouldRenderInfoNote ? (
                    <BasketPartsSelectionInfoNote variant={errorNoteVariant} message={errorNote || infoNote} />
                  ) : null,

                  manufacturer: manufacturerCell,
                  partdesc: !isForOrderPart ? <S.QuantityText>{partdesc}</S.QuantityText> : undefined,
                  quantity: !isForOrderPart ? (
                    <div>
                      <S.QuantityText>
                        {availableQuantity} {unit}
                      </S.QuantityText>
                    </div>
                  ) : (
                    <S.ExtendedDeliveryTimeNote>{extendedDeliveryDateLabel}</S.ExtendedDeliveryTimeNote>
                  ),
                  partDiscount: compilePartDiscountCol(),
                  select: (
                    <div>
                      {isAsAWhole ? (
                        <S.Checkbox
                          id={id}
                          label={addToBasketInputLabel}
                          checked={hasDraftQuantity}
                          hideLabel
                          onChange={handleCheckboxToggle}
                        />
                      ) : (
                        <S.AddToBasketInput
                          variant="rounded"
                          id={id}
                          label={addToBasketInputLabel}
                          min={productMinQuantity}
                          max={availableQuantity}
                          step={quantityModulo}
                          nowInBasket={nowInBasket}
                          busy={busy}
                          small
                          showZeroPlaceholder
                          onUpdate={partQuantitySetter}
                          onEnterKeyDown={partQuantitySetter}
                          transformValueBeforeUpdate={beforeUpdateValueTransformer}
                          onBlur={() => {
                            if (!validationErrorNotes.current.has(formattedId)) {
                              currentInputErrorCleared.current = true;
                            }
                          }}
                        />
                      )}
                      {showAddMoreToOrderBtn ? (
                        <div>{renderAddNextButtonWrapper(true, addNextPartLabel, handleAddNextPartButtonClick)}</div>
                      ) : null}
                    </div>
                  ),
                };
              })}
          />
        </S.SimpleBar>

        <S.Summary>
          <S.Totals>
            {t('orderTotalCost')}:
            <p>
              {' '}
              {totalQuantity}
              {parts?.[0]?.unit ? ` ${parts[0].unit}` : ''}
            </p>
          </S.Totals>

          <S.Buttons>
            <S.Button
              variant="quaternary"
              inverseBusyIcon
              iconBefore={false}
              busy={busy}
              isRounded
              busyChildren={loadingLabel}
              disabled={
                !validationErrorDismissedRef.current && validationErrorNotes.current.size > 0
                  ? true
                  : !isAnyPartInBasket && totalQuantity === 0
              }
              onClick={() => {
                if (validationErrorNotes.current.size === 0) {
                  const inputHadErrorButWasCleared =
                    !validationErrorDismissedRef.current && currentInputErrorCleared.current;

                  if (inputHadErrorButWasCleared) {
                    currentInputErrorCleared.current = false;
                  }

                  handleAddToBasketButtonClick();
                }
              }}
            >
              {t(isAnyPartInBasket ? 'updateBasket' : 'addToBasket')}
            </S.Button>
          </S.Buttons>
        </S.Summary>
      </S.BasketPartsSelection>
    );
  }, [
    addNextPartLabel,
    additionalToOrderEntriesSize,
    addToBasketInputLabel,
    busy,
    createMemoizedPartsQuantitiesListSetter,
    extendedDeliveryDateLabel,
    handleAddToBasketButtonClick,
    hasAddMoreToOrderButton,
    isAnyPartInBasket,
    isModal,
    loadingLabel,
    mustStayLabel,
    partMaxToTakeLabel,
    parts,
    partsQuantitiesList,
    productName,
    productImgSrc,
    t,
    totalQuantity,
  ]);

  useEffect(() => {
    if (!parts.length) return;

    setPartsQuantitiesList(
      parts.map((part, partIdx) => {
        const { id, parttemplate, qtyinbasket: quantity } = part;
        const isTemplate = !!parttemplate;

        return { id: createPartId(id, partIdx, isTemplate), quantity, isTemplate };
      }),
    );
  }, [parts]);

  useEffect(() => {
    if (additionalToOrderEntriesSize > 0 && scrollBarRef?.current?.contentWrapperEl) {
      const simpleScrollBarWrapper = scrollBarRef.current.contentWrapperEl;
      simpleScrollBarWrapper.scrollTop = simpleScrollBarWrapper.scrollHeight;
    }
  }, [additionalToOrderEntriesSize]);

  if (isModal) {
    return (
      <>
        <>{children(openModal)}</>

        <Modal
          isOpen={isOpen}
          onCloseClick={closeModal}
          onEscapeKeydown={closeModal}
          onBackgroundClick={closeModal}
          isRounded
          onContainerClick={() => {
            validationErrorDismissedRef.current = true;
            forceUpdate();
          }}
        >
          {renderContent()}
        </Modal>
      </>
    );
  }

  return <>{renderContent()}</>;
};

BasketPartsSelection.defaultProps = defaultProps;
