import { Fragment } from 'react';

import useCurrentCurrency from 'src/hooks/useCurrentCurrency';

import { priceColorsKeys, priceColorsMap } from 'src/enums/priceColors';

import * as S from './styles';

const defaultProps = {
  showVariants: false,
  variant: 'primary',
  selectedPriceTypeApiId: null,
};

export const BasketPartSelectionPriceList = ({
  currsig,
  showVariants,
  variant,
  userType,
  selectedPriceTypeApiId,
  promo,
  items,
  unit,
  ...restProps
} = defaultProps) => {
  const transformPrice = useCurrentCurrency({
    sign: currsig,
  });
  const listToShow = items.filter((item) => item.priceend > 0);

  const labelAndAmountHidden = listToShow.length === 1 && listToShow[0].quantity === 1;

  const renderCrossedPrice = (item) =>
    item.pricecross ? (
      <S.CrossedPrice className="crossed-price">
        <span>{transformPrice(item.pricecross)}</span>
        <S.PriceUnit>{unit}</S.PriceUnit>
      </S.CrossedPrice>
    ) : null;

  if (listToShow.length < 1) {
    return null;
  }

  return (
    <S.ProductPricesList {...restProps}>
      {listToShow.map((item, i, array) => {
        if (!item.priceend) {
          return null;
        }

        let priceVariant = priceColorsKeys.NORMAL;
        if (showVariants) {
          priceVariant = promo !== 0 ? priceColorsMap.get(promo) : priceVariant;
        }

        const addBottomMargin = i === array.length - 1;
        const addMarginBetweenPrices = i !== array.length - 1;
        const isFirstPriceOnTheList = true;

        return (
          <Fragment key={i}>
            <S.PriceContainer
              className="priceContainer"
              addBottomMargin={addBottomMargin}
              addMarginBetweenPrices={addMarginBetweenPrices}
              labelAndAmountHidden={labelAndAmountHidden}
            >
              <S.Amount
                labelAndAmountHidden={labelAndAmountHidden}
                variant={variant}
                isShowCrossedPrice={!!item.pricecross}
                shouldAlignToTop={item.lastprice}
                isFirstPriceOnTheList={isFirstPriceOnTheList}
              >
                {item.quantity}
              </S.Amount>

              <S.Price priceVariant={priceVariant} variant={variant} className="price" withLastPrice={item.lastPrice}>
                {labelAndAmountHidden ? renderCrossedPrice(item) : null}
                {item.isSelected && <S.SelectedIcon icon="arrowRight" />}
                {!labelAndAmountHidden ? renderCrossedPrice(item) : null}
                <S.PriceValue variant={variant} isFirstPriceOnTheList={isFirstPriceOnTheList}>
                  {transformPrice(item.priceend)}/{unit}
                </S.PriceValue>
                {item.lastprice ? <S.LastPriceInfo lastPrice={item.lastprice} /> : null}
              </S.Price>
            </S.PriceContainer>
          </Fragment>
        );
      })}
    </S.ProductPricesList>
  );
};
BasketPartSelectionPriceList.defaultProps = defaultProps;
